import { React, useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col, Form, Modal } from "react-bootstrap";
import CouponMessages from "../components/ComponenetHelper/CouponMessages";
import { useLocation } from "react-router-dom";
import { Sendreq } from "../req";
import { useHistory } from "react-router-dom";

import $ from "jquery";

const OrderDetails = () => {
  const { pathname } = useLocation();
  const nameForm = useRef(null);
  const history = useHistory();

  const [FirstName, SetFirstName] = useState("");
  const [LastName, SetLastName] = useState("");
  const [Email, SetEmail] = useState("");
  const [Phone, SetPhone] = useState("");
  const [cupon, Setcupon] = useState("");
  const [TotalPriceOld, SetTotalPriceOld] = useState(0);
  const [show, setShow] = useState(false);
  const [cuponMessage, SetcuponMessage] = useState(null);
  const [cuponObject, SetcuponObject] = useState(null);
  const [Terms, SetTerms] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [OrderProject, SetOrderProject] = useState([]);
  useEffect(() => {
    SetOrderProject(JSON.parse(sessionStorage.getItem("order")));
    SetTotalPriceOld(
      JSON.parse(sessionStorage.getItem("order"))["totalprices"]
    );
    async function fetchData() {
      const GetWelcome = await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/GetWelcome",
        {}
      );
      if (GetWelcome.status === 200) {
        SetTerms(GetWelcome[0]["Terms"]);
        console.log(GetWelcome[0]["Terms"]);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  async function UseCoupon() {
    if (cupon === "") {
      return;
    }
    const Cupon = await Sendreq(
      "https://bengurion.wdev.co.il/api/client/ClientProcesses/UseCoupon",
      {
        CouponCode: cupon,
        Price: OrderProject["totalprices"],
        OrderDate: OrderProject["SelectedDate"],
      }
    );
    if (Cupon !== false) {
      SetcuponObject(Cupon);
      SetcuponMessage(true);
      SetOrderProject({
        ...OrderProject,
        totalprices: Cupon["PriceNow"],
        useCoupon: true,
      });
      var temp = JSON.parse(sessionStorage.getItem("order"));
      temp["totalprices"] = Cupon["PriceNow"];
      temp["useCoupon"] = true;
      sessionStorage.setItem("order", JSON.stringify(temp));
    } else {
      SetcuponMessage(false);
    }
  }
  function NameValid(str) {
    return /^[a-z\u0590-\u05fe]+$/i.test(str);
  }
  function PhoneValid(str) {
    // eslint-disable-next-line no-useless-escape
    return /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|([71,72,73,74,75,76,77]{2}\d{7})|[5]{1}\d{8})$/.test(
      str
    );
  }
  function EmailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  function Validate() {
    var erros = false;
    const form = nameForm.current;
    ///console.log(form)
    $(".form-control").removeClass("is-invalid");
    $(".Error").text("");
    if (FirstName.trim() === "") {
      form["FirstName"].className = "is-invalid form-control";
      $("#FirstNameError").text("יש למלא שם פרטי");
      erros = true;
    } else if (NameValid(FirstName) === false) {
      erros = true;
      form["FirstName"].className = "is-invalid form-control";
      $("#FirstNameError").text(
        "השם פרטי יכול להכיל אותיות אנגלית או עברית בלבד"
      );
    }

    if (LastName.trim() === "") {
      form["LastName"].className = "is-invalid form-control";
      $("#LastNameError").text("יש למלא שם משפחה");
      erros = true;
    } else if (NameValid(LastName) === false) {
      erros = true;
      form["LastName"].className = "is-invalid form-control";
      $("#LastNameError").text(
        "השם פרטי יכול להכיל אותיות אנגלית או עברית בלבד"
      );
    }
    if (Email.trim() === "") {
      form["Email"].className = "is-invalid form-control";
      $("#EmailError").text("יש למלא מייל");
      erros = true;
    } else if (EmailValid(Email) === false) {
      erros = true;
      form["Email"].className = "is-invalid form-control";
      $("#EmailError").text("להקיש כתובת מייל חוקית");
    }

    if (Phone.trim() === "") {
      form["Phone"].className = "is-invalid form-control";
      $("#PhoneError").text("יש למלא מספר פלאפון");
      erros = true;
    } else if (PhoneValid(Phone) === false) {
      erros = true;
      form["Phone"].className = "is-invalid form-control";
      $("#PhoneError").text("יש למלא מספר טלפון תקין");
    }
    if (!$("#Term").is(":checked")) {
      erros = true;
      $("#TermError").text("יש לאשר את התנאים שלנו");
      $("#Term").addClass("is-invalid");
    }
    return erros;
  }
  async function nopost() {
    if (Validate()) {
      return;
    }
    var temp = JSON.parse(sessionStorage.getItem("order"));
    temp["orderpersondetails"] = {
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      Phone: Phone,
    };
    sessionStorage.setItem("order", JSON.stringify(temp));
    const insertOrder = await Sendreq(
      "https://bengurion.wdev.co.il/api/client/ClientProcesses/InsertOrder",
      { ...JSON.parse(sessionStorage.getItem("order")) }
    );
    if (insertOrder === false) {
      alert("פעולה נכשלה");
      return;
    }
    sessionStorage.setItem(
      "order",
      JSON.stringify({ ...temp, orderid: insertOrder })
    );
    if (OrderProject["totalprices"] > 0) history.push("/orderform");
    else if (OrderProject["totalprices"] === 0) {
      history.push("/thanks");
    }
  }
  return (
    <Container className="p-4">
      <div className="orderDetailspage d-flex justify-content-center">
        <div>
          <h1 style={{ textAlign: "center" }}>פרטי הזמנה</h1>
          <div className="d-flex justify-content-center">
            <Form ref={nameForm}>
              <Row className="orderData">
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">שם פרטי</label>
                    <Form.Control
                      autofocus
                      name="FirstName"
                      value={FirstName}
                      onChange={(e) => {
                        SetFirstName(e.target.value);
                      }}
                    />
                    <p className="Error" id="FirstNameError"></p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">שם משפחה</label>
                  </div>
                  <Form.Control
                    name="LastName"
                    value={LastName}
                    onChange={(e) => {
                      SetLastName(e.target.value);
                    }}
                  />
                  <p className="Error" id="LastNameError"></p>
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">מייל</label>
                    <Form.Control
                      name="Email"
                      value={Email}
                      onChange={(e) => {
                        SetEmail(e.target.value);
                      }}
                    />
                    <p className="Error" id="EmailError"></p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">טלפון</label>
                  </div>
                  <Form.Control
                    name="Phone"
                    value={Phone}
                    onChange={(e) => {
                      SetPhone(e.target.value);
                    }}
                  />
                  <p className="Error" id="PhoneError"></p>
                </Col>
                <div className="space"></div>
                <Form.Check
                  label="אני מעוניין לקבל עדכונים על מוצרים ופעילויות חדשות"
                  name="group1"
                  type="checkbox"
                />
                <div className="form-check">
                  <input
                    id="Term"
                    name="group1"
                    type={"checkbox"}
                    className="form-check-input"
                  />
                  <label title="" className="form-check-label">
                    אני מאשר{" "}
                  </label>
                  <p className="AproveTerms" onClick={handleShow}>
                    {" "}
                    את תנאי השימוש ומדיניות הביטולים{" "}
                  </p>
                </div>
                <p className="Error" id="TermError"></p>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">קוד קופון</label>
                  </div>
                  <Form.Control
                    value={cupon}
                    onChange={(e) => {
                      Setcupon(e.target.value);
                    }}
                  />
                  {OrderProject["useCoupon"] === false && (
                    <span onClick={UseCoupon} className="UseCoupon">
                      הפעלת קופון
                    </span>
                  )}
                  {cuponMessage === true ? (
                    <CouponMessages
                      Bool={cuponMessage}
                      Dis={cuponObject.Present}
                      Now={cuponObject.PriceNow}
                      Before={TotalPriceOld}
                    />
                  ) : (
                    <CouponMessages Bool={cuponMessage} />
                  )}
                </Col>
                <div className="space"></div>
                <p style={{ fontSize: "16px" }}>
                  סה"כ מחיר: {OrderProject["totalprices"] + "₪"}
                </p>
                <Button
                  onClick={nopost}
                  className="nextbtn"
                  variant="primary"
                ></Button>{" "}
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>תנאי שימוש</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: Terms }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            סגור
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default OrderDetails;
