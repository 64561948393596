import "bootstrap/dist/css/bootstrap.min.css";
import Numericadditionalatrakts from "./Numericadditionalatrakts";

const TypeRounter = (props) => {
  const {
    updateAddisionalAtractCart,
    Price,
    AtractTitle,
    id,
    OutOfStock,
    RestartPrices,
    CardType,
    isAllowedToUpdateCart,
    sumsum,
  } = props;
  if (OutOfStock) {
    return <h1>אזל המלאי!</h1>;
  }
  if (props.type === 1) {
    return (
      <>
        <Numericadditionalatrakts
          isAllowedToUpdateCart={isAllowedToUpdateCart}
          sumsum={sumsum}
          RestartPrices={RestartPrices}
          Price={Price}
          AtractTitle={AtractTitle}
          id={id}
          updateAddisionalAtractCart={updateAddisionalAtractCart}
          dis={props.dis}
          UpdateTotalPrice={props.UpdateTotalPrice}
          MainTotalCart={props.MainTotalCart}
          restartQLY={props.restartQLY}
          qly={props.qly}
          setqly={props.setqly}
          data={props.data}
          CardType={CardType}
          basket={props.basket}
        />
      </>
    );
  }
  if (props.type === 2) return <></>;
};

export default TypeRounter;
