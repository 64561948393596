import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

const NumericNumbers = (props) => {
  const {
    UpdatePrice,
    updateMainAtractCart,
    title,
    Price,
    Type_Name,
    id,
    AllowBuy,
    RestartPrices,
    SetTotalQlyMainTickets,
    TotalQlyMainTickets,
    StockAllow,
    SelectedHour,
  } = props;
  useEffect(() => {
    if (RestartPrices !== 0 && RestartPrices !== -1) {
      setqly(0);
    }
  }, [RestartPrices]);
  const UpdateQly = (action) => {
    console.log(AllowBuy);
    if (AllowBuy) {
      if (action === "add") {
        if (StockAllow) {
          SetTotalQlyMainTickets(TotalQlyMainTickets + 1);
          setqly(qly + 1);
          if (UpdatePrice != null) UpdatePrice("add", Number(Price));
          let temp = {
            CustomerTypeName: Type_Name,
            id: id,
            price: Price,
            title: title,
          };
          updateMainAtractCart(temp, "add");
        }
      } else {
        if (Number(qly) - 1 < 0) {
          setqly(0);
        } else {
          SetTotalQlyMainTickets(TotalQlyMainTickets - 1);
          setqly(qly - 1);
          if (UpdatePrice != null) UpdatePrice("minus", Number(Price));
          /// var  temp=props.data;
          /// temp['title']=restdata.title;
          let temp = {
            CustomerTypeName: Type_Name,
            id: id,
            price: Price,
            title: title,
          };
          /// console.log(temp);
          updateMainAtractCart(temp, "minus");
        }
      }
    }
  };
  const [qly, setqly] = useState(0);
  return (
    <div className="animate__animated animate__fadeIn mt-1">
      <Container className={SelectedHour ? "" : "Close"}>
        <Row>
          <Col style={{}} xs={4} md={4}>
            <div className="ptype-div">
              <div style={{ display: "flex", width: "100%" }}>
                {/* <img alt="Crowd Icon" className="CrowdIcone" src="/crowd.png" /> */}
                <p className="ptype">{Type_Name}</p>
              </div>
            </div>
            
          </Col>
          <Col xs={2} className="mx-2">
            {" "}
            <div
              style={{
                display: "flex",
                placeItems: "center",
                width: "100%",
                height: "95%",
                fontSize: "0.9rem",
              }}
            >
              ₪{Price}
            </div>
          </Col>
          <Col className="mx-1" xs={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
              className="number"
            >
              <span
                style={{ display: "flex", placeItems: "center" }}
                onClick={() => {
                  UpdateQly("minus");
                }}
                className=" btn  minus"
                x
              >
                -
              </span>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{qly}</p>
              </div>
              <span
                style={{ marginTop: "2px" }}
                onClick={() => {
                  UpdateQly("add");
                }}
                className="btn plus"
              >
                +
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NumericNumbers;
