/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { React, useReducer, useEffect, useState } from "react";
import { Container, ThemeProvider, Button, Col, Row } from "react-bootstrap";
import { jsonobj, AddisionalAtract, MainAtraction } from "./data";
import Maincartlist from "./components/ComponenetHelper/Maincartlist";
import Mainevent from "./components/mainevent";
import MaineventRefactor from "./components/maineventRefactor";

import Orderdetails from "./components/orderdetails";
import Additionalatt from "./components/additionalattraction";
import Header from "./components/header";
import Welcome from "./components/Welcome";
import Adisionalcartlist from "./components/ComponenetHelper/Adisionalcartlist";
import Payform from "./components/Payform";
import Thanks from "./components/thanks";
import { Sendreq } from "./req";
import Spinner from "./Spinner.gif";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  const [zeroit, Setzeroit] = useState(0);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [Addtobaket, SetAddtobaket] = useState(true);
  const [MainTotalCart, SetMainTotalCart] = useState([]);
  const [AdditionalAttraction, SetAdditionalAttraction] = useState([]);
  const [restartQLY, SetrestartQLY] = useState(false);
  const [TotalCart, SetTotalCard] = useState({
    MainAtract: [],
    Addisionalatract: [],
  });
  const [TotalPrices, SetTotalPrices] = useState(0);
  const [MainAtractCart, SetMainAtractCart] = useState([]);
  const [AddisionalAtractCart, SetAddisionalAtractCart] = useState([]);
  const [SubAtracts, SetSubAtracts] = useState([]);
  const [AtractType, SetAtractType] = useState(1);
  const [DataObject, SetDataObject] = useState([]);
  const [Loading, SetLoading] = useState(true);
  const [StartDates, SetStartDates] = useState([]);
  const [DatePick, SetDatePick] = useState([]);
  const [LockMainCard, SetLockMainCard] = useState(true);
  const [MainAtractID, SetMainAtractID] = useState(0);
  const [OpenHours, SetOpenHours] = useState([]);
  const [AllowBuy, SetAllowBuy] = useState(false);
  const [SelectedDate, SetSelectedDate] = useState("בחירת תאריך");
  const [SelectedHour, SetSelectedHour] = useState(false);
  const [RestartPrices, SetRestartPrices] = useState(-1);
  const [MaintAtractAvailStcok, SetMaintAtractAvailStcok] = useState(0);
  const [MainAtractStock, SetMainAtractStock] = useState(0);
  const [DisNext, SetdisNext] = useState(true);

  useEffect(() => {
    if (MainAtractStock <= 0) {
      SetdisNext(true);
    } else {
      SetdisNext(false);
    }
    //alert(MainAtractStock);
  }, [MainAtractStock]);

  useEffect(() => {
    if (SelectedHour === "בחירת שעה") {
      SetAllowBuy(false);
    }
    if (SelectedDate !== "בחירת תאריך") {
      async function OpeningHours() {
        const OpenHoursObj = await Sendreq(
          "https://bengurion.wdev.co.il/api/client/ClientProcesses/ReturnOpenHours",
          { date: SelectedDate, atractid: DataObject["MainData"][0]["id"] }
        );
        SetOpenHours(OpenHoursObj);
        // console.log(OpenHoursObj, "OpenHoursObj");
      }
      OpeningHours();
    }
    if (SelectedDate !== "בחירת תאריך" && SelectedHour !== "בחירת שעה") {
      async function fetchData() {
        const subatract = await Sendreq(
          "https://bengurion.wdev.co.il/api/client/ClientProcesses/GetSubAtractByDateTime",
          {
            time: SelectedHour,
            date: SelectedDate,
            mainatract: DataObject["MainData"][0]["id"],
          }
        );
        SetAllowBuy(true);
        SetSubAtracts(subatract);
      }
      fetchData();
    }

    if (SelectedHour === "בחירת שעה") {
      SetAddisionalAtractCart([]);
      SetMainAtractCart([]);
      SetTotalPrices(0);
      SetRestartPrices(RestartPrices + 1);
    }
  }, [DataObject, RestartPrices, SelectedDate, SelectedHour]);

  function SetHourSelected(Hour) {
    SetSelectedHour(Hour);
    SetAddisionalAtractCart([]);
    SetMainAtractCart([]);
    SetTotalPrices(0);
    SetRestartPrices(RestartPrices + 1);
  }
  function sumQuantity(array) {
    return array.reduce((total, item) => total + parseInt(item.qly, 10), 0);
  }
  const updateAddisionalAtractCart = (object, action) => {
    const exist = AddisionalAtractCart.find((x) => x.id === object.id);
    console.log("is this what you looking for>?");
    console.log(sumQuantity(MainAtractCart), "sususus");
    console.log(AddisionalAtractCart, "AddisionalAtractCart");
    console.log(MainAtractCart, "MainAtractCart");

    if (exist) {
      // console.log(AddisionalAtractCart);
      if (action === "add") {
        SetAddisionalAtractCart(
          AddisionalAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly + 1 } : x
          )
        );
      } else {
        SetAddisionalAtractCart(
          AddisionalAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly - 1 } : x
          )
        );
      }
    } else {
      SetAddisionalAtractCart([...AddisionalAtractCart, { ...object, qly: 1 }]);
    }
  };
  const updateMainAtractCart = (object, action) => {
    const exist = MainAtractCart.find((x) => x.id === object.id);
    if (exist) {
      if (action === "add") {
        SetMainAtractCart(
          MainAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly + 1 } : x
          )
        );
      } else {
        SetMainAtractCart(
          MainAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly - 1 } : x
          )
        );
      }
    } else {
      SetMainAtractCart([...MainAtractCart, { ...object, qly: 1 }]);
    }
  };
  const UpdateTotalPrice = (action, price) => {
    action === "add"
      ? SetTotalPrices(price + TotalPrices)
      : SetTotalPrices(TotalPrices - price);
  };
  async function SetDate(Date) {
    var selected = document.getElementById("HourSelect");
    selected.selectedIndex = 0;
    console.log(
      DataObject["MainData"][0]["id"],
      'DataObject["MainData"][0]["id"]'
    );
    const dates = await Sendreq(
      "https://bengurion.wdev.co.il/api/client/ClientProcesses/PickedDate",
      { Date: Date, AtractID: DataObject["MainData"][0]["id"] }
    );

    ///// פה בשדרוג לעשות את זה !גם בשרת
    //console.log(dates);
    //SetMaintAtractAvailStcok()
    SetDatePick(dates);
    SetSelectedDate(Date);
    console.log(dates, "dates");
  }
  // useEffect(() => {
  //   if (TotalPrices === 0) {
  //     SetAddtobaket(false);
  //   } else {
  //     SetAddtobaket(true);
  //   }
  // }, [TotalPrices]);
  useEffect(() => {
    async function fetchData() {
      let yourDate = new Date().toISOString().split("T")[0];
      const dates = await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/Getdates",
        {}
      );
      const closingdates = await Sendreq(
        "https://bengurion.wdev.co.il/api/admin/management/returnclosingdays",
        []
      );
      const Data = await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/GetData",
        { Type: AtractType, Date: yourDate }
      );
      let SubAtractions = await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/GetDataSubAtractionsdefault",
        { BelongTo: Data["MainData"][0]["id"] }
      );

      const filteredArray = dates.map((el1) => {
        // console.log(el1, "el1");
        return {
          Date: el1.Date,
          DayName: el1.DayName,
          ForeignFormat: el1.ForeignFormat,
          isClosed: closingdates.some(
            (el2) => el1.ForeignFormat === el2.Closing_Date
          ),
        };
      });
      const finalDates = filteredArray.filter((el) => !el.isClosed);
      // const filteredArray = dates.filter((item) => {
      //   return item.ForeignFormat !== closingdates.Closing_Date;
      // });
      // console.log(dates, finalDates, "dates", { CLOSINGDATE: closingdates });
      SetLoading(true);
      SetStartDates(finalDates);
      SetDataObject(Data);
      SetOpenHours(Data["OpenHours"]);
      SetMainAtractID(Data["MainData"][0]["id"]);
      SetSubAtracts(SubAtractions);
      SetLoading(false);
    }
    fetchData();
  }, [AtractType]);

  useEffect(() => {
    var temp = TotalCart;
    temp["MainAtract"] = MainTotalCart;
    temp["Addisionalatract"] = AdditionalAttraction;

    SetTotalCard(temp);
    forceUpdate();
  }, [AdditionalAttraction, MainTotalCart]);
  useEffect(() => {
    if (MainTotalCart.length === 0) {
      SetrestartQLY(true);
    } else {
      SetrestartQLY(false);
    }
  }, [MainTotalCart]);
  if (Loading) {
    return (
      <div className="splash animate__animated animate__fadeIn">
        <img src={Spinner} alt="loading..." />
      </div>
    );
  }

  return (
    <Container>
      <Router>
        <div className="App ">
          <header className="App-header py-3 animate__animated animate__fadeIn">
            <Header />
          </header>
          <ThemeProvider dir="rtl">
            <Switch>
              <Route exact path="/">
                <Welcome
                  SelectedHour={SelectedHour}
                  SelectedDate={SelectedDate}
                  SetAddisionalAtractCart={SetAddisionalAtractCart}
                  SetTotalPrices={SetTotalPrices}
                  SetMainAtractCart={SetMainAtractCart}
                  SetHourSelected={SetHourSelected}
                  RestartPrices={RestartPrices}
                  SetRestartPrices={SetRestartPrices}
                  AtractType={AtractType}
                  SetAtractType={SetAtractType}
                />

                {/* <MaineventRefactor
                  SetMainAtractStock={SetMainAtractStock}
                  RestartPrices={RestartPrices}
                  newtotalprice={zeroit}
                  AllowBuy={AllowBuy}
                  SelectedHour={SelectedHour}
                  SetHourSelected={SetHourSelected}
                  LockMainCard={LockMainCard}
                  key={6554}
                  DatePick={DatePick}
                  SetDate={SetDate}
                  UpdateTotalPrice={UpdateTotalPrice}
                  StartDates={StartDates}
                  objectdata={MainAtraction()[0]}
                  ticketdata={jsonobj()}
                  OpenHours={OpenHours}
                  DataObjectBASIC={DataObject["MainData"][0]}
                  DataObjectCustomersType={DataObject["CustomerType"]}
                  updateMainAtractCart={updateMainAtractCart}
                /> */}
                <Mainevent
                  SetMainAtractStock={SetMainAtractStock}
                  RestartPrices={RestartPrices}
                  newtotalprice={zeroit}
                  AllowBuy={AllowBuy}
                  SelectedHour={SelectedHour}
                  SetHourSelected={SetHourSelected}
                  LockMainCard={LockMainCard}
                  key={6554}
                  DatePick={DatePick}
                  SetDate={SetDate}
                  UpdateTotalPrice={UpdateTotalPrice}
                  StartDates={StartDates}
                  objectdata={MainAtraction()[0]}
                  ticketdata={jsonobj()}
                  OpenHours={OpenHours}
                  DataObjectBASIC={DataObject["MainData"][0]}
                  DataObjectCustomersType={DataObject["CustomerType"]}
                  updateMainAtractCart={updateMainAtractCart}
                />
                <Container>
                  <div className="RestAttractions animate__animated  animate__fadeIn">
                    {AtractType === 1 && (
                      <h1>העשירו את הביקור בצריף עם אטרקציות נוספות</h1>
                    )}
                  </div>
                </Container>
                {SubAtracts &&
                  SubAtracts.map((item, index) => (
                    <Additionalatt
                      MainAtractCart={MainAtractCart}
                      RestartPrices={RestartPrices}
                      key={item.id * 10}
                      {...item}
                      UpdateTotalPrice={UpdateTotalPrice}
                      updateAddisionalAtractCart={updateAddisionalAtractCart}
                      AdditionalAttraction={AdditionalAttraction}
                      SetAdditionalAttraction={SetAdditionalAttraction}
                      index={index}
                      AddisionalAtractCart={AddisionalAtractCart}
                      MainTotalCart={MainTotalCart}
                      restartQLY={restartQLY}
                      data={AddisionalAtract()[0]}
                      type={1}
                      basket={Addtobaket}
                      SelectedHour={SelectedHour}
                    />
                  ))}
                <Container>
                  <div className="TotalCarT animate__fadeIn">
                    <Row>
                      <Col className="SepLine" md={6}>
                        <Row>
                          <Col md={6}>
                            <div className="attractionType">
                              <span className="MainCheckOutTitle">
                                סוגי פעילות
                              </span>
                              <Maincartlist
                                type={"atractname"}
                                MainAtractCart={MainAtractCart}
                              />
                              {AddisionalAtractCart.map((item, index) => (
                                <Adisionalcartlist
                                  SelectedHour={SelectedHour}
                                  key={index}
                                  type={"atractname"}
                                  fullobject={AddisionalAtractCart}
                                  cart={item}
                                />
                              ))}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="TotaAttractPrices">
                              <span className="MainCheckOutTitle"> מחיר</span>
                              <ul className="MainCheckoutPricesList">
                                <Maincartlist
                                  type={"price"}
                                  MainAtractCart={MainAtractCart}
                                />
                                {AddisionalAtractCart.map((item, index) => (
                                  <Adisionalcartlist
                                    key={index}
                                    type={"price"}
                                    fullobject={AddisionalAtractCart}
                                    cart={item}
                                  />
                                ))}
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <div className="TotalPriceCheckoutSession ">
                          <p>
                            {" "}
                            סה"כ לתשלום:{" "}
                            <span
                              style={{ color: "#40AE49", fontSize: "1rem" }}
                            >
                              ₪{TotalPrices}
                            </span>
                          </p>
                          <div className="d-flex justify-content-center">
                            <Link to={true ? "/orderdetails" : "#"}>
                              <Button
                                disabled={DisNext}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "order",
                                    JSON.stringify({
                                      mainatractid: MainAtractID,
                                      mainatract: MainAtractCart,
                                      adisional: AddisionalAtractCart,
                                      useCoupon: false,
                                      totalprices: TotalPrices,
                                      SelectedDate: SelectedDate,
                                      SelectedHour: SelectedHour,
                                    })
                                  );
                                }}
                                className="nextbtn"
                                variant="primary"
                              ></Button>{" "}
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div className="MoblieTotals">
                  <Container>
                    <Row>
                      <Col xs={4}>
                        <div className="tot">
                          <p>
                            {" "}
                            סה"כ לתשלום:{" "}
                            <span
                              style={{ color: "#40AE49", fontSize: "1rem" }}
                            >
                              ₪{TotalPrices}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <Link to={MainAtractStock > 0 ? "/orderdetails" : "#"}>
                          <Button
                            disabled={DisNext}
                            onClick={() => {
                              sessionStorage.setItem(
                                "order",
                                JSON.stringify({
                                  mainatractid: MainAtractID,
                                  mainatract: MainAtractCart,
                                  adisional: AddisionalAtractCart,
                                  useCoupon: false,
                                  totalprices: TotalPrices,
                                  SelectedDate: SelectedDate,
                                  SelectedHour: SelectedHour,
                                })
                              );
                            }}
                            className="nextbtn"
                            variant="primary"
                          ></Button>{" "}
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Route>
              <Route path="/orderform">
                <Payform />
              </Route>
            </Switch>
            <Route path="/orderdetails">
              <Orderdetails />
            </Route>
            <Route path="/thanks">
              <Thanks />
            </Route>
          </ThemeProvider>
        </div>
      </Router>
    </Container>
  );
}

export default App;
