const Numericadditionalatrakts = (props) => {
  const {
    dis,
    Price,
    id,
    AtractTitle,
    updateAddisionalAtractCart,
    qly,
    setqly,
    isAllowedToUpdateCart,
    sumsum,
  } = props;
  const work = (action) => {
    if (dis) {
      console.log(
        dis,
        "dis Numericadditionalatrakts",
        sumsum,
        "sumsum Numericadditionalatrakts",
        qly,
        "qly Numericadditionalatrakts",
        isAllowedToUpdateCart,
        "isAllowedToUpdateCart Numericadditionalatrakts",
        "action isAllowedToUpdateCart",
        action
      );
      if (action === "add") {
        console.log("thats why Im outit");
        console.log(sumsum > qly, "sumsum, qly");
        if (sumsum > qly) {
          console.log("thats why Im init");
          setqly(action);
          var object = {};
          object["title"] = AtractTitle;
          object["id"] = id;
          object["price"] = Price;
          updateAddisionalAtractCart(object, action);
        }
      }
      if (action !== "add") {
        setqly(action);
        var object = {};
        object["title"] = AtractTitle;
        object["id"] = id;
        object["price"] = Price;
        updateAddisionalAtractCart(object, action);
      }
    }
  };
  return (
    <div className="additionalA px-3">
      <div className="griding">
        <div
          style={{
            display: "flex",
            placeItems: "center",
            width: "100%",
            height: "100%",
          }}
          className="someClassName"
        >
          {props.CardType === "0" && (
            <p
              style={{
                fontSize: "0.9rem",
                display: "grid",
                gridTemplateColumns: "auto auto",
                gap: "5px",
                placeItems: "center",
                width: "100%",
              }}
              className="TotalCostAtr"
            >
              עלות כרטיס:{" "}
              <span
                className="pricespan"
                style={{ fontSize: "16px", color: "#40AE49" }}
              >
                {" "}
                ₪{Price}{" "}
              </span>{" "}
            </p>
          )}
        </div>

        <div className="ptype-div mt-2">
          <div
            className="pInnerSub"
            style={{ display: "flex", placeItems: "center", width: "100%" }}
          >
            <img
              alt="CrowdIcon additional"
              className="additional"
              src={process.env.PUBLIC_URL + "/crowd.png"}
            />
            <p className="ptype mt-3sub ">בחירת כמות</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          className="number"
        >
          <button
            disabled={qly <= 0}
            style={{ display: "flex", placeItems: "center" }}
            onClick={() => {
              work("minus");
            }}
            className="minus btn"
          >
            -
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>{qly}</p>
          </div>
          <button
            style={{ display: "flex", placeItems: "center", marginTop: "3px" }}
            disabled={props.dis ? false : true}
            onClick={() => {
              work("add");
            }}
            className="btn plus"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Numericadditionalatrakts;
