import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const SubAtractnumericnumbers = (props) => {
  const { updateMainAtractCart, title, RestartPrices } = props;
  const {
    Price,
    AtractID,
    Type_Name,
    id,
    AllowBuy,
    Update,
    OutOfStock,
    maxAllowedToBuy,
    isAllowedToUpdateCart,
  } = props;
  console.log(
    isAllowedToUpdateCart,
    "isAllowedToUpdateCartisAllowedToUpdateCart"
  );
  // TODO: get the qly for this attract
  const [qly, setqly] = useState(0);
  useEffect(() => {
    console.log(maxAllowedToBuy, "maxAllowedToBuy");
  }, [maxAllowedToBuy]);
  useEffect(() => {
    if (RestartPrices !== -1 && RestartPrices !== 0) {
      setqly(0);
    }
  }, [RestartPrices]);
  useEffect(() => {
    console.log(AllowBuy, "AllowBuy");
  }, []);
  const UpdateQly = (action) => {
    console.log("action", action, qly);
    if (AllowBuy) {
      if (action === "add" ) {
        if(!isAllowedToUpdateCart) {
          return;
        }
        setqly(qly + 1);
        Update("add", Number(Price));
        var newtitle = title + " - " + Type_Name;
        var temp = {
          CustomerTypeName: Type_Name,
          id: id,
          price: Price,
          title: newtitle,
          SubAtractid: AtractID,
        };
        console.log(temp);
        updateMainAtractCart(temp, "add");
      } else {
        if (Number(qly) - 1 < 0) {
          setqly(0);
        } else {
          setqly(qly - 1);
          Update("minus", Number(Price));
          var tempData = {
            CustomerTypeName: Type_Name,
            id: id,
            price: Price,
            title: title,
            SubAtractid: AtractID,
          };
          console.log(tempData);
          updateMainAtractCart(tempData, "minus");
        }
      }
    }
  };
  if (OutOfStock) return <h1>אזל המלאי!</h1>;
  return (
    <div className="animate__animated animate__fadeIn mt-4">
      <Container>
        <Row>
          <Col style={{}} xs={5} md={5}>
            <div className="ptype-div">
              <div
                className="pInner"
                style={{ display: "flex", placeItems: "center", width: "100%" }}
              >
                <img alt="Crowd Icon" className="CrowdIcone" src="/crowd.png" />
                <p className="ptype">{Type_Name}</p>
              </div>
            </div>
          </Col>
          <Col xs={2} className="mx-2">
            {" "}
            <div
              style={{
                display: "flex",
                placeItems: "center",
                width: "100%",
                height: "95%",
                fontSize: "0.9rem",
              }}
            >
              ₪{Price}
            </div>
          </Col>
          <Col className="mx-1" xs={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
              className="number"
            >
              <button
                disabled={qly - 1 < 0}
                style={{ display: "flex", placeItems: "center" }}
                onClick={() => {
                  console.log("click");
                  UpdateQly("minus");
                }}
                className=" btn  minus"
              >
                -
              </button>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{qly}</p>
              </div>
              <button
                style={{ marginTop: "3px" }}
                onClick={() => {
                  UpdateQly("add");
                }}
                className="btn plus"
              >
                +
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubAtractnumericnumbers;
