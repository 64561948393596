
import { useEffect, useState } from "react"


const MainCartList = ({ MainAtractCart, type }) => {
    const [price, setprice] = useState(0);
    const [name, setname] = useState(0);
    useEffect(() => {
        var tempprice = 0;
        for (var i = 0; i < MainAtractCart.length; i++) {
            console.log(MainAtractCart)
            setname(MainAtractCart[i]['title']);
            tempprice += MainAtractCart[i].price * MainAtractCart[i].qly
        }
        setprice(tempprice);
    }, [MainAtractCart])
    if (price === 0) {
        return (
            <></>
        );
    }
    else {
        if (type === 'price') {
            return (
                <li>{'₪' + price  }</li>
            );
        }
        if (type === 'atractname') {
            return (
                <li>{name}</li>
            );
        }
    }
}

export default MainCartList;