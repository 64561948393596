import { useEffect, useState } from "react";
const OpenTimeDIV = ({ OpenHours }) => {
  // eslint-disable-next-line no-unused-vars
  const [openTimes, setOpenTimes] = useState({});

  useEffect(() => {
    // console.log(openTimes, "openTimes");
    // console.log(OpenHours, "OpenHours");
    setOpenTimes({
      day: OpenHours.HEDayName,
      start: OpenHours.StartTime,
      end: OpenHours["EndTime"],
    });
  }, [OpenHours]);

  return (
    <>
      <div className="OpenTimes" style={{ marginTop: "20px" }}>
        <div className="OpenTimes_inner">
          {/* <img src="./clock.svg" /> */}
          שעות פעילות:{" "}
          <b>
            {OpenHours["HEDayName"] +
              " משעה - " +
              OpenHours["StartTime"].slice(0, 5) +
              " עד " +
              OpenHours["EndTime"].slice(0, 5)}
          </b>
        </div>
      </div>
    </>
  );
};

export default OpenTimeDIV;
