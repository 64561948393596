import { React, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Sendreq } from "../req";

const Thanks = () => {
  useEffect(() => {
    async function fetchData() {
      await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/Aprove",
        { orderid: OrderCart.orderid }
      );
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [OrderCart, _] = useState(JSON.parse(sessionStorage.getItem("order")));
  return (
    <Container className="p-4">
      <div className="orderDetailspage d-flex justify-content-center">
        <div>
          <h1 style={{ textAlign: "center" }}>
            <span style={{ color: "#40AE49" }}>מספר הזמנה : </span>{" "}
            {OrderCart.orderid}
          </h1>
          <h1 style={{ textAlign: "center", marginTop: "35px" }}>פרטי מזמין</h1>
          <div className="d-flex justify-content-center">
            <Form>
              <Row className="orderData">
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">שם פרטי</label>
                    <Form.Control
                      value={OrderCart.orderpersondetails.FirstName}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">שם משפחה</label>
                  </div>
                  <Form.Control value={OrderCart.orderpersondetails.LastName} />
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">מייל</label>
                    <Form.Control value={OrderCart.orderpersondetails.Email} />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="inputblock">
                    <label className="inputlabel">טלפון</label>
                  </div>
                  <Form.Control value={OrderCart.orderpersondetails.Phone} />
                </Col>
                <div className="space"></div>
                <div className="space"></div>
              </Row>
            </Form>
          </div>
          <Row className="Reception">
            <Col>
              <span>שם אטרקציה</span>
            </Col>
            <Col>
              <span>כמות</span>
            </Col>
            <Col>
              <span>מחיר</span>
            </Col>
          </Row>
          {OrderCart.mainatract.map((item, index) => (
            <>
              <Row className="ReceptionOrder">
                <Col>
                  <img
                    alt="ticket icon"
                    className="ticketicone"
                    src="ticketicon.png"
                  />
                  <span>{item.title + " - " + item.CustomerTypeName}</span>
                </Col>
                <Col>
                  <span>{item.qly}</span>
                </Col>
                <Col>
                  <span> {Number(item.price) * Number(item.qly)} ₪</span>
                </Col>
              </Row>
            </>
          ))}
          {OrderCart.adisional.map((item, index) => (
            <>
              <Row className="ReceptionOrder">
                <Col>
                  <img
                    alt="ticket icone"
                    className="ticketicone"
                    src="ticketicon.png"
                  />
                  <span>{item.title}</span>
                </Col>
                <Col>
                  <span>{item.qly}</span>
                </Col>
                <Col>
                  <span> {Number(item.price) * Number(item.qly)} ₪</span>
                </Col>
              </Row>
            </>
          ))}
        </div>
      </div>
      <div className="TotalPriceOrder">
        <h1>
          סה"כ שולם :{" "}
          <span style={{ color: "#40AE49" }}>{OrderCart.totalprices} ₪</span>
        </h1>
        <p>
          *SMS ומייל ישלחו בדקות הקרובות. אנא שמור את מספר ההזמנה על מנת להציגה
          בקופה.
        </p>
      </div>
    </Container>
  );
};
export default Thanks;
