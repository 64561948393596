import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { Sendreq } from "../req";

const Welcome = (props) => {
  const {
    AtractType,
    // RestartPrices,
    // SetRestartPrices,
    // SetAddisionalAtractCart,
    // SetMainAtractCart,
    // SetTotalPrices,
  } = props;
  const [Title, SetTitle] = useState("");
  const [Description, SetDescription] = useState("");
  const [Message, SetMessage] = useState("");
  useEffect(() => {
    async function fetchData() {
      const GetWelcome = await Sendreq(
        "https://bengurion.wdev.co.il/api/client/ClientProcesses/GetWelcome",
        {}
      );
      SetTitle(GetWelcome[0]["Title"]);
      SetDescription(GetWelcome[0]["Contect"]);
      SetMessage(GetWelcome[0]["message"]);
    }
    fetchData();
  }, []);
  // function ResetAll() {
  //   SetAddisionalAtractCart([]);
  //   SetMainAtractCart([]);
  //   SetTotalPrices(0);
  //   SetRestartPrices(RestartPrices + 1);
  // }
  return (
    <>
      <Container>
        <Row>
          <div className="d-flex justify-content-center animate__animated animate__fadeIn">
            <Col md={7}>
              <div className="welcomeSession">
                <h1>{Title}</h1>
                <p>{Description}</p>
                <Row>
                  {/* <Col md={12}>
                    <Button
                      onClick={() => {
                        SetAtractType(1);
                        ResetAll();
                      }}
                      className={
                        AtractType === 1
                          ? "ticket-btn green"
                          : "ticket-btn grey"
                      }
                    >
                      רכישת כרטיסים למתחם הצריף
                    </Button>
                  </Col> */}
                  {/* <Col md={6}><Button onClick={() => { SetAtractType(2); ResetAll(); }} className={AtractType === 2 ? "ticket-btn green" : "ticket-btn grey"} >רכישת כרטיסים למיצג במכון</Button></Col> */}
                </Row>
              </div>
            </Col>
          </div>
        </Row>
        {Message !== "" && AtractType === 1 && (
          <span className="UpdateMessage animate__animated animate__fadeIn">
            <img alt="red flag" src="red-flag.svg" />
            {Message}
          </span>
        )}
      </Container>
    </>
  );
};

export default Welcome;
