import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";

import App from "./App";
import "./index.css";

import { Accessibility } from "accessibility/dist/accessibility";

var options = {};
const pixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const icons = {
  position: {
    top: { size: 100, units: "px" },
    right: { size: 0, units: "px" },
    type: "fixed",
  },
};
ReactPixel.init("368579821965102", pixelOptions);
ReactPixel.pageView();
var labels = {
  resetTitle: "שחזור למקורי",
  closeTitle: "סגירה",
  menuTitle: "נגישות",
  increaseText: "הגדלת גודל טקסט",
  decreaseText: "הקטנת גודל טקסט",
  increaseTextSpacing: "הגדלת מרווח טקסט",
  decreaseTextSpacing: "הקטנת מרווח טקסט",
  invertColors: "היפוך צבעים",
  grayHues: "צבעי אפור",
  underlineLinks: "קו תחתון מתחת ללינקים",
  bigCursor: "מצביע עכבר גדול",
  readingGuide: "מדריך קריאה",
  textToSpeech: "טקסט לדיבור",
  speechToText: "דיבור לטקסט",
  disableAnimations: "ביטול אנימציות",
};

options.icons = icons;
options.labels = labels;

options.textToSpeechLang = "he-IL"; // or any other language
options.speechToTextLang = "he-IL"; // or any other language

window.addEventListener(
  "load",
  function () {
    new Accessibility(options);
  },
  false
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
