import React, { useEffect, useState } from "react";

const Adisionalcartlist = (props) => {
  const [price, setprice] = useState(0);
  const [name, setname] = useState(0);
  const { type, cart, fullobject } = props;

  useEffect(() => {
    if (cart) {
      console.log(cart, fullobject,"cart");
      if (cart.qly >= 0) {
        console.log("bitch");
        var tempprice = cart.price * cart.qly;
        setprice(tempprice);
        setname(cart.title);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullobject]);

  if (price === 0) {
    return <li></li>;
  } else {
    if (type === "price") {
      return <li>{"₪" + price}</li>;
    }
    if (type === "atractname") {
      return <li>{name}</li>;
    }
  }
};

export default Adisionalcartlist;
