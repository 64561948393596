/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NumericRow from "./ComponenetHelper/numericnumbers";
import OpenTimeDIV from "./ComponenetHelper/OpenTimeDIV";

const MainEvent = (props) => {
  const {
    objectdata,
    StartDates,
    SetDate,
    DatePick,
    DataObjectBASIC,
    SetHourSelected,
    SelectedHour,
    SetMainAtractStock,
    updateMainAtractCart,
    RestartPrices,
    UpdateTotalPrice,
    LockMainCard,
    DataObjectCustomersType,
    OpenHours,
    AllowBuy,
  } = props;

  const [Totalcart, SetTotalcart] = useState([]);
  const [TotalPirce, SetTotalPirce] = useState(0);
  // console.log(DataObjectBASIC, "dataobject");
  const [qly, setqly] = useState(1);
  const [TotalMainAtractPrice, SetTotalMainAtractPrice] = useState(0);

  const [TotalQlyMainTickets, SetTotalQlyMainTickets] = useState(0);
  const [StockAllow, SetStockAllow] = useState(true);
  const [SelectedIndexStock, SetSelectedIndexStock] = useState(-1);

  const temp = DataObjectCustomersType.sort((a, b) => {
    return a.id - b.id;
  });
  // TODO: check if necessary
  useEffect(() => {
    console.log(DatePick, "DatePick");
    window.onpopstate = (e) => {
      window.location.reload();
      // console.log("popstate", e);
    };
  }, [TotalQlyMainTickets]);
  useEffect(
    (DatePick, SelectedIndexStock) => {
      if (SelectedIndexStock > 0) {
        var allowToBuy = parseInt(DatePick[SelectedIndexStock - 1].Stock);
        if (allowToBuy <= TotalQlyMainTickets) {
          SetStockAllow(false);
        } else {
          SetStockAllow(true);
        }
      }
      SetMainAtractStock(TotalQlyMainTickets);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [TotalQlyMainTickets]
  );
  function PrintElement(index) {
    SetSelectedIndexStock(parseInt(index));
  }
  useEffect(() => {
    if (RestartPrices !== 0 && RestartPrices !== -1) {
      SetTotalMainAtractPrice(0);
    }
  }, [RestartPrices]);

  const setTotalAtractPrice = (action, price) => {
    if (action === "add") {
      SetTotalMainAtractPrice(price + TotalMainAtractPrice);
      UpdateTotalPrice("add", price);
    } else {
      SetTotalMainAtractPrice(TotalMainAtractPrice - price);
      UpdateTotalPrice("minus", price);
    }
  };
  useEffect(
    (SelectedHour) => {
      // console.log(SelectedHour);
    },
    [DatePick]
  );
  useEffect(() => {
    var total = 0;
    for (var i = 0; i < Totalcart.length; i++) {
      total += Totalcart[i].price;
    }
    SetTotalPirce(total);
  }, [Totalcart]);
  return (
    <Container>
      <div className="mainEventCard  animate__animated animate__fadeIn">
        <Row>
          <Col md={4}>
            <div className="CardPhoto">
              <img alt="main" src={DataObjectBASIC.MainPhoto} />
            </div>
          </Col>
          <Col className="colafter" md={4}>
            <div className="CardText">
              <h1> {DataObjectBASIC.AtractTitle} </h1>
              <p>{DataObjectBASIC.Description}</p>
              <OpenTimeDIV OpenHours={OpenHours} />
            </div>
          </Col>
          <Col sm={4} md={4}>
            <div className="CardPrices">
              <Form.Select
                onChange={(e) => {
                  SetDate(e.target.value);
                }}
                style={{ marginTop: "12px", width: "90%" }}
                aria-label="בחירת תאריך"
              >
                <option value={0}>בחירת תאריך</option>
                {StartDates.map((item, index) => (
                  <option
                    style={{ marginTop: "12px", width: "100%" }}
                    key={index}
                    value={item.ForeignFormat}
                  >
                    {item.Date + " - " + item.DayName}
                  </option>
                ))}
              </Form.Select>

              <Form.Select
                disabled={DatePick.length === 0}
                id={"HourSelect"}
                onChange={(e) => {
                  console.log(e.target.selectedIndex, "e.target.selectedIndex");
                  SetHourSelected(e.target.value);
                  PrintElement(e.target.selectedIndex);
                }}
                value={SelectedHour}
                style={{ marginTop: "12px", width: "90%" }}
                aria-label="בחירת שעה"
              >
                <option value={0}>בחירת שעה</option>
                {DatePick &&
                  DatePick.map((item, index) => {
                    console.log(item, "item", DatePick, "DatePick");
                    return (
                      <option
                        // selectedIndex={index}
                        key={item.id}
                        value={item.Hour}
                      >
                        {"הגעה ב - " +
                          item.Hour.substring(0, 5) +
                          " מלאי - " +
                          item.Stock +
                          " כרטיס"}
                      </option>
                    );
                  })}
              </Form.Select>
              <div className="mt-2 animate__animated animate__fadeIn">
                {temp.map((item, index) => (
                  <div key={index}>
                    <NumericRow
                      SelectedHour={SelectedHour}
                      key={index}
                      StockAllow={StockAllow}
                      TotalQlyMainTickets={TotalQlyMainTickets}
                      SetTotalQlyMainTickets={SetTotalQlyMainTickets}
                      RestartPrices={RestartPrices}
                      AllowBuy={AllowBuy}
                      DatePick={DatePick}
                      LockMainCard={LockMainCard}
                      title={DataObjectBASIC.AtractTitle}
                      DataObjectCustomersType={DataObjectCustomersType}
                      {...item}
                      restdata={objectdata.AttractionData[0]}
                      qly={qly}
                      UpdatePrice={setTotalAtractPrice}
                      updateMainAtractCart={updateMainAtractCart}
                    />
                  </div>
                ))}
              </div>
              <div className="checkoutline "></div>

              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                  margin: "10px auto",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: 600,
                    alignItems: "center",
                    margin: "0 auto",
                    paddingY: "1em",
                    whiteSpace: "nowrap",
                  }}
                  className="TotalCostAtr"
                >
                  {" "}
                  סה"כ עלות כרטיס:{" "}
                  <p
                    className="pricespan"
                    style={{
                      fontSize: "25px",
                      color: "#40AE49",
                      marginRight: "5px",
                    }}
                  >
                    {" "}
                    ₪{TotalMainAtractPrice}{" "}
                  </p>
                </p>
                <br />
                <br />
              </div>
            </div>
            <br />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default MainEvent;
