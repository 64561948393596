import { React, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import TypeRounter from "./ComponenetHelper/TypeRounter";
import TotaladdisionalPrice from "./ComponenetHelper/TotaladdisionalPrice";
import OpenTimeDIV from "./ComponenetHelper/OpenTimeDIV";
import SubAtractnumericnumbers from "./ComponenetHelper/SubAtractnumericnumbers";

const AddisionalAtracttaction = (props) => {
  function sumQuantity(array) {
    return array.reduce((total, item) => total + parseInt(item.qly, 10), 0);
  }
  function countTotalQly(subAttractions) {
    if (subAttractions.length === 0) {
      return {};
    }
    const totalQly = {};

    for (const subAttraction of subAttractions) {
      const subAttractionId = subAttraction.SubAtractid;
      const qly = subAttraction.qly;

      if (subAttractionId in totalQly) {
        totalQly[subAttractionId] += qly;
      } else {
        totalQly[subAttractionId] = qly;
      }
    }

    return totalQly;
  }
  const [qly, setqly] = useState(0);
  const [totalpriceCard, SettotalpriceCard] = useState(0);
  const [allowUpdate, setAllowUpdate] = useState();
  const {
    AtractTitle,
    MainPhoto,
    Description,
    Price,
    id,
    AddisionalAtractCart,
  } = props;
  const [sumsum, setSumsum] = useState(0);
  const [countTotalQlyState, setCountTotalQlyState] = useState();
  const {
    UpdateTotalPrice,
    OpenTime,
    CardType,
    CustomerType,
    AvlStock,
    RestartPrices,
    updateAddisionalAtractCart,
    type,
    MainAtractCart,
  } = props;
  useEffect(() => {
    setCountTotalQlyState(countTotalQly(AddisionalAtractCart));
  }, [AddisionalAtractCart]);
  useEffect(() => {
    console.log(
      countTotalQlyState,
      sumsum,
      "countTotalQlyStatecountTotalQlyStatecountTotalQlyState"
    );
    if (countTotalQlyState) {
      console.log(countTotalQlyState, "ehehehehehhehehheh");
      setAllowUpdate(!(countTotalQlyState[id] >= sumsum));
    } else {
      setAllowUpdate(false);
    }
  }, [countTotalQlyState]);
  useEffect(() => {
    console.log(MainAtractCart, "MainAtractCart");
    const resSum = sumQuantity(MainAtractCart);
    console.log(resSum, "resSum");
    setSumsum(resSum);
  }, [MainAtractCart]);
  useEffect(() => {
    // console.log(props.basket, "props.basket ");
    if (RestartPrices !== -1 && RestartPrices !== 0) {
      SettotalpriceCard(0);
      setqly(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RestartPrices]);
  useEffect(() => {
    console.log(props.basket, "popo ");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.basket]);

  const updateQly = (action) => {
    console.log(sumsum, "sumsum");
    console.log(countTotalQlyState[id], "countTotalQlyState[id]1");
    console.log(sumsum > countTotalQlyState[id]);
    if (action === "add") {
      console.log(countTotalQlyState[id], "countTotalQlyState[id]");
      console.log(countTotalQlyState[id] > sumsum, "מרוצריף");
      if (countTotalQlyState[id] >= sumsum) {
        return;
      }
      setqly(qly + 1);
      var object = {};
      object["title"] = AtractTitle;
      object["id"] = id;
      object["price"] = Number(Price);
      /// updateAddisionalAtractCart(object,'add');
      SettotalpriceCard(Number(Price) * (qly + 1));
      UpdateTotalPrice("add", Number(Price));
    } else {
      if (qly - 1 < 0) {
        setqly(0);
      } else {
        setqly(qly - 1);
        var obj = {};
        obj["title"] = AtractTitle;
        obj["id"] = id;
        obj["price"] = Price;
        SettotalpriceCard(Number(Price) * (qly - 1));
        UpdateTotalPrice("minus", Number(Price));
      }
    }
  };

  const updateQly2 = (action, price) => {
    if (action === "add") {
      console.log(countTotalQlyState[id], sumsum, "pupupupupupuppu");
      if (countTotalQlyState[id] >= sumsum) {
        return;
      }

      const updatedTotalPrice = Number(price) + totalpriceCard;
      UpdateTotalPrice("add", Number(price));
      SettotalpriceCard(updatedTotalPrice);
    } else {
      setqly(qly - 1);
      const object = {
        title: AtractTitle,
        id: id,
        price: Price,
      };
      //// updateAddisionalAtractCart(object,'minus');
      const updatedTotalPrice = totalpriceCard - Number(price);
      UpdateTotalPrice("minus", Number(price));
      SettotalpriceCard(updatedTotalPrice);
    }

    console.log(countTotalQlyState[id], "countTotalQlyState[id] updateQly2");
  };

  function OutOfStock() {
    if (AvlStock <= 0) {
      return true;
    } else {
      return false;
    }
  }

  const tem = CustomerType || [];
  const temp = tem.sort((a, b) => {
    return a.id - b.id;
  });

  return (
    <Container>
      <div
        className={
          "addtionalEventCard " + (props.SelectedHour ? "Open" : "Close")
        }
      >
        <Row>
          <Col md={4}>
            <div className="CardPhoto">
              <img alt="main" src={MainPhoto}></img>
            </div>
          </Col>
          <Col className="colafter" md={4}>
            <div className="CardTextAdt">
              <h1> {AtractTitle} </h1>
              <p style={{ color: "#4E5861" }}>{Description}</p>
              <OpenTimeDIV OpenHours={OpenTime} />
              {AvlStock != null && <span>מלאי זמין : {AvlStock}</span>}
            </div>
          </Col>
          <Col
            style={{
              marginTop: "1.75rem",
            }}
            sm={4}
            md={4}
          >
            <div className="CardPrices2 ">
              {CardType === "0" ? (
                <TypeRounter
                  sumsum={sumsum}
                  isAllowedToUpdateCart={allowUpdate}
                  CardType={CardType}
                  RestartPrices={RestartPrices}
                  OutOfStock={OutOfStock()}
                  Price={Price}
                  updateAddisionalAtractCart={updateAddisionalAtractCart}
                  dis={props.basket}
                  qly={qly}
                  setqly={updateQly}
                  basket={props.basket}
                  // type={data}
                  AtractTitle={AtractTitle}
                  id={id}
                  type={type}
                />
              ) : (
                false
              )}
              {CardType === "1" &&
                temp.map((item, index) => (
                  <div key={index} className="subA-section">
                    <SubAtractnumericnumbers
                      RestartPrices={RestartPrices}
                      OutOfStock={OutOfStock()}
                      updateMainAtractCart={updateAddisionalAtractCart}
                      isAllowedToUpdateCart={allowUpdate}
                      {...item}
                      maxAllowedToBuy={sumsum}
                      AllowBuy={props.basket}
                      qly={qly}
                      Update={updateQly2}
                      title={AtractTitle}
                      AtractID={id}
                    />
                  </div>
                ))}
              <br />
              <div
                style={{ margin: "0 auto" }}
                className="checkoutline mt-3"
              ></div>
              <TotaladdisionalPrice totalprice={totalpriceCard} />
              <div className="d-flex justify-content-center"></div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default AddisionalAtracttaction;
