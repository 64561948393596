const CouponMessages = (props) => {
    const { Bool, Dis, Now, Before } = props;
    if (Bool) {
        return (
            <>
                <div className="coupondiv-succ">
                    <span>הקופון הופעל בהצלחה! כעת תשלם {Now} במקום - {Before} סה"כ הנחה של  - {Dis}% </span>
                </div>
            </>
        );
    }
    if (Bool === null) {
        return (
            <>
            </>
        );
    }
    else {
        return (
            <>
                <div className="coupondiv-faile">
                    <span>הפעלת הקופון כשלה</span>
                </div>
            </>
        );
    }
}

export default CouponMessages;