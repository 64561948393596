import axios from "axios";
import "alertifyjs/build/css/alertify.css";
export async function Sendreq(Url, dataArray) {
  return new Promise((resolve, reject) => {
    var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(dataArray));
    axios({
      method: "post",
      url: Url,
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        if (response.data.status === true) {
          resolve(response.data.data);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        alert("בקשה נכשלה");
        resolve(false);
      });
  });
}
