
const TotaladdisionalPrice = (props) => {
    return (
        <>
            <br />
            <div className="TotaladdisionalPrice">
                <br />
                <p style={{ fontSize: '16px', height: '100%' }}>סה"כ מחיר הכרטיס:</p><p style={{ fontSize: '16px' }} className="greentotalprice">₪{props.totalprice}</p>
            </div>
            <br />
        </>
    );
}

export default TotaladdisionalPrice;
