
import React from "react"
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'

const Header = () => {
  return (
    <>
      <Container>
        <div className="MainLogoB">
          <a href={window.location.origin}><img alt="LOGO" className="" src={process.env.PUBLIC_URL + '/logo.png'}></img></a>
        </div>
      </Container>
    </>
  );
}

export default Header;